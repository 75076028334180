import { desktopFirstV3 } from 'style/breakpointsv3';
import styled from 'styled-components';
import { toScreen } from 'utils/media-query/responsive.util';

export const ResponsiveContainer = styled.div`
  max-width: 1144px;
  margin: 0 auto;

  ${toScreen(desktopFirstV3.lg)} {
    max-width: 1080px;
  }

  ${toScreen(desktopFirstV3.md)} {
    max-width: 712px;
  }

  ${toScreen(desktopFirstV3.sm)} {
    max-width: 425px;
  }

  ${toScreen(desktopFirstV3.xs)} {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
