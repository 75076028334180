// default is xs: < 458, no need to set
export const mobileFirstV3 = {
  sm: 458,
  md: 776,
  lg: 1144,
  xl: 1366
};

// default is md: > 1144, no need to set
export const desktopFirstV3 = {
  lg: mobileFirstV3.xl - 1,
  md: mobileFirstV3.lg - 1,
  sm: mobileFirstV3.md - 1,
  xs: mobileFirstV3.sm - 1
};
