import React from 'react';

const VideoComputerIcon = ({ color = '#D42525', size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.91455H4C2.89543 2.91455 2 3.80998 2 4.91455V12.9146C2 14.0192 2.89543 14.9146 4 14.9146H16C17.1046 14.9146 18 14.0192 18 12.9146V4.91455C18 3.80998 17.1046 2.91455 16 2.91455ZM4 0.914551C1.79086 0.914551 0 2.70541 0 4.91455V12.9146C0 15.1237 1.79086 16.9146 4 16.9146H16C18.2091 16.9146 20 15.1237 20 12.9146V4.91455C20 2.70541 18.2091 0.914551 16 0.914551H4ZM18 19.9146C18 20.4668 17.5523 20.9146 17 20.9146H3C2.44772 20.9146 2 20.4668 2 19.9146C2 19.3623 2.44772 18.9146 3 18.9146H17C17.5523 18.9146 18 19.3623 18 19.9146ZM12.608 9.63492C13.1307 9.28034 13.1307 8.54877 12.608 8.19419L9.49797 6.08413C8.873 5.66011 8 6.07994 8 6.80449V11.0246C8 11.7492 8.873 12.169 9.49796 11.745L12.608 9.63492Z"
        fill={color}
      />
    </svg>
  );
};

export default VideoComputerIcon;
